import styled from 'styled-components';

export const TrilhaHeader = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-start;
gap: 12px;
border-bottom: 1px solid #693299 !important;
margin-bottom: 10px !important;
padding-bottom: 16px !important;


.title-modulo {
  transition: 0.2s;
  h2 {
    white-space: nowrap !important;
    margin-right: 10px !important;
    color: #d5dbdb !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: block !important;
    padding-bottom: 5px !important;
  }
}

.li-buttonTrilha {

    display: flex;
    align-items: center;
    height: 32px;
    flex-grow: 1 !important;
    text-align: left !important;
    background: transparent !important;
    border-radius: 6px;
    border: 1px solid #671ea7;
    padding: 0px 10px;

    letter-spacing: 0.25px !important;
    color: #671ea7;
    background: #fff!important;
    font-size: 12px !important;
    outline: none !important;
    cursor: pointer !important;

    transition: .1s;

    &:focus {
      outline: 0px solid ${({ theme: { colors } }) =>
    colors.secondary}!important;
    }

    &.selected {
      background: #671ea7 !important;
      border: 0px !important;
      color: #fff;
      .icon {
        flex-shrink: 0 !important;
        margin-right: 10px !important;
        width: 16px !important;
        height: 16px !important;
        overflow: hidden !important;
        backface-visibility: hidden !important;
        position: relative !important;
        svg {
          color: #fff;
          font-size: 16px !important;
        }
      }

    }

    &:hover {
      background: #5a1994 !important;
      border: 0px;
      color: #fff;
      svg {
        color: #fff !important;
      }
    }




    .icon {
      flex-shrink: 0 !important;
      margin-right: 10px !important;
      width: 16px !important;
      height: 16px !important;
      overflow: hidden !important;
      backface-visibility: hidden !important;
      position: relative !important;
      svg {
        color: #671ea7;
        font-size: 16px !important;
      }
    }


  }

`;

export const TrilhaContainer = styled.div`
width: 100%;

.title-modulo {
  transition: 0.2s;
  h2 {
    white-space: nowrap !important;
    margin-right: 10px !important;
    color: #d5dbdb !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: block !important;
    padding-bottom: 5px !important;
  }
}

  
`
export const TrilhaTelasContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 12px;



  

    .li-buttonTrilhaTela {
        display: flex;
        align-items: center;
        height: 64px;
        flex-grow: 1 !important;
        text-align: left !important;
        background: transparent !important;
        border-radius: 6px;
        border: 1px solid #fff;
        padding: 0px 10px;

        letter-spacing: 0.25px !important;
        color: #fff;
        background: #671ea7!important;
        font-size: 16px !important;
        outline: none !important;
        cursor: pointer !important;

        transition: .1s;

        &:focus {
          outline: 1px solid ${({ theme: { colors } }) =>
    colors.secondary}!important;
        }

        &:selected {
          background: #9800fe !important;
          color: #fff;
        }

        &:hover {
          background: #fff !important;
          color: #671ea7;
          svg {
            color: #671ea7 !important;
          }
        }
        


        .icon {
          flex-shrink: 0 !important;
          margin-right: 10px !important;
          width: 32px !important;
          height: 32px !important;
          overflow: hidden !important;
          backface-visibility: hidden !important;
          position: relative !important;
       
          svg {
            color: #fff;
            font-size: 32px !important;
          
          }
        }

  }
`

export const Li = styled.li`
  display: flex !important;
  flex-grow: 1 !important;
  flex-direction: column !important;

  .telasModulo {
    .card-tela {
      ol {
        margin: 1px !important;
        display: block !important;
        padding: 0px 30px 0px 30px !important;
      }
    }

    .title-modulo {
      transition: 0.2s;
      h2 {
        white-space: nowrap !important;
        margin-right: 10px !important;
        color: #d5dbdb !important;
        font-size: 18px !important;
        font-weight: bold !important;
        display: block !important;
        padding-bottom: 5px !important;
      }
    }

    @media (min-width: 940px) {
      .title-modulo {
        display: flex !important;
        align-items: baseline !important;
        border-bottom: 1px solid #693299 !important;
        margin-bottom: 10px !important;
      }
    }
    @media (max-width: 940px) {
      .title-modulo {
        padding: 0px 30px !important;
      }
    }
  }

  @media (min-width: 940px) {
    .telasModulo {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }

  @media (max-width: 940px) {
    .telasModulo {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }
`;
