/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { ModulosTelas, Tela } from '../../types';
import { Li } from './styles';

interface ModuloProps {
  descricao: string;
  filtro: Tela[];
  renderTelas?: (telasModulo: ModulosTelas['telas']) => JSX.Element[];
}

const Modulo: React.FC<ModuloProps> = (props) => {
  const { descricao, filtro, renderTelas = () => null } = props;
  return (
    <div>
      <Li>
        <div className="telasModulo">
          <div className="card-tela">
            <ol>{renderTelas(filtro)}</ol>
          </div>
          <div className="title-modulo">
            <h2>{descricao}</h2>
          </div>
        </div>
      </Li>
    </div>
  );
};

export default Modulo;
