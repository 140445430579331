import styled from 'styled-components';

export const Li = styled.li`
  display: flex !important;
  flex-grow: 1 !important;
  flex-direction: column !important;

  .telasModulo {
    .card-tela {
      ol {
        margin: 1px !important;
        display: block !important;
        padding: 0px 30px 0px 30px !important;
      }
    }

    .title-modulo {
      transition: 0.2s;
      h2 {
        white-space: nowrap !important;
        margin-right: 10px !important;
        color: #d5dbdb !important;
        font-size: 18px !important;
        font-weight: bold !important;
        display: block !important;
        padding-bottom: 5px !important;
      }
    }

    @media (min-width: 940px) {
      .title-modulo {
        display: flex !important;
        align-items: baseline !important;
        border-bottom: 1px solid #693299 !important;
        margin-bottom: 10px !important;
      }
    }
    @media (max-width: 940px) {
      .title-modulo {
        padding: 0px 30px !important;
      }
    }
  }

  @media (min-width: 940px) {
    .telasModulo {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }

  @media (max-width: 940px) {
    .telasModulo {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }
`;
