import React, { useContext } from 'react';

import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { Tela } from '../../types';
import { Card, CardLink } from './styles';
import { NavigationContext } from '~/context/navigation';
import { useQueryClient } from 'react-query';

interface TelaCardProps {
  tela: Tela;
  Icon: any;
  blocked?: boolean;
  handleOpenTela?: (item: any) => void;
  handleAddAccessed?: (cod_tela: any) => Promise<void>;
  handleFavorite?: (
    cod_tela: number,
    cod_modulo: number,
    flg_favorita: any,
  ) => Promise<void>;
}

const TelaCard: React.FC<TelaCardProps> = (props) => {
  const { screens, setScreenActive } = useContext(NavigationContext);
  const {
    tela,
    Icon,
    blocked = false,
    handleOpenTela = () => null,
    handleAddAccessed = () => null,
    handleFavorite = () => null,
  } = props;

  const queryClient = useQueryClient();
  return (
    <Link
      to={blocked ? '/app' : tela.url}
      style={{
        color: 'inherit',
        textDecoration: 'inherit',
      }}
      key={tela.label_menu}
      onClick={(e) => {
        /**
         * Ao tentar adicionar uma tela já aberta, fazemos um
         * find para verificar a existência da tela, e se
         * ela já estiver aberta setamos a aba como ativa.
         * Se for uma tela nova impede a navegação para a rota da tela selecionada
         * quando já estamos com o total de telas abertas.
         */
        if (screens.length >= 6) {
          const filteredScreens = screens.find(
            (item) => item.cod_tela === tela.cod_tela,
          );
          if (filteredScreens) {
            queryClient.setQueryData(`cod_tela_active`, {
              cod_tela: filteredScreens.cod_tela,
            });
            setScreenActive(filteredScreens.cod_tela);
            return;
          }
          e.preventDefault();
        }
      }}
    >
      <Card
        className={blocked ? 'tela blocked' : 'tela'}
        tabIndex={0}
        onClick={() => {
          if (blocked) return;
          if (tela.content !== null) handleAddAccessed(tela.cod_tela);
          handleOpenTela(tela);
        }}
        onKeyPress={(e) => {
          if (e.key === ' ') {
            if (blocked) return;
            handleAddAccessed(tela.cod_tela);
            handleOpenTela(tela);
          }
        }}
      >
        <div className="tela-panel">
          <div className="flex-panel">
            <div className="icon">{Icon && <Icon />}</div>
            <div className="tela-info">
              <div className="tela-title">
                <h3>
                  <CardLink className={blocked ? 'blocked' : ''}>
                    <span>{tela.des_tela}</span>
                  </CardLink>
                </h3>
                {tela.flg_favorita ? (
                  <AiFillStar
                    color="#ffd700"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleFavorite(
                        tela.cod_tela,
                        tela.cod_modulo,
                        tela.flg_favorita,
                      );
                    }}
                  />
                ) : (
                  <AiOutlineStar
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleFavorite(
                        tela.cod_tela,
                        tela.cod_modulo,
                        tela.flg_favorita,
                      );
                    }}
                  />
                )}
              </div>
              <span className="tela-description">
                {tela.des_funcao ? tela.des_funcao : `Abrir ${tela.label_menu}`}
              </span>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default TelaCard;
