import styled from 'styled-components';

export const Card = styled.li`
  &.tela {
    width: 100% !important;
    border: 1px solid transparent !important;
    display: flex !important;
    padding: 13px !important;
    box-shadow: 0 0.5px 1px 0.5px rgb(0 0 0 / 40%) !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
    margin-bottom: 5px !important;
    flex-direction: column !important;
    background-color: #671ea7 !important;

    transition: 0.2s;

    &:focus-within {
      outline: 1px solid ${({ theme: { colors } }) => colors.secondary} !important;
    }

    cursor: pointer;

    .tela-panel {
      padding: 2px !important;

      .flex-panel {
        display: flex !important;

        .icon {
          flex-shrink: 0 !important;
          margin-right: 10px !important;
          width: 24px !important;
          height: 24px !important;
          overflow: hidden !important;
          backface-visibility: hidden !important;
          position: relative !important;
          color: #fff !important;

          svg {
            color: #fff !important;
            font-size: 24px !important;
          }
        }

        .tela-info {
          width: 100% !important;
          display: flex !important;
          flex-grow: 1 !important;
          flex-direction: column !important;
          padding: 0 !important;

          .tela-title {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;

            svg {
              font-size: 18px !important;
              color: #fff;

              cursor: pointer;
            }

            h3 {
              display: block !important;
              line-height: 14px;

              a {
                display: inline !important;
                outline: none !important;
                text-decoration: none !important;
                cursor: pointer !important;

                span {
                  color: #fff !important;
                  font-size: 14px !important;
                  font-weight: normal !important;

                  cursor: inherit !important;
                }
                @media (min-width: 940px) {
                  span {
                    font-size: 18px !important;
                  }
                }
              }
            }
            @media (min-width: 940px) {
              .tela-none {
                display: none !important;
              }
              h3 {
                margin-bottom: 5px !important;
              }
            }
          }
          .tela-description {
            color: #d4bcfa !important;
            overflow: hidden !important;
            font-size: 12px !important;
            line-height: 20px !important;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
          }
          @media (min-width: 940px) {
            .tela-description {
              font-size: 14px !important;
            }
          }
        }
      }
    }
    @media (min-width: 940px) {
      .tela-panel {
        height: 47px !important;
        overflow: hidden !important;
        position: relative !important;
        transition: height 250ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s !important;
      }
    }
  }
  &.blocked {
    background-color: #5e4873 !important;

    .tela-panel {
      .flex-panel {
        .icon {
          svg {
            color: #d3d3d3 !important;
          }
        }
        .tela-info {
          .tela-title {
            h3 {
              a {
                span {
                  color: #d3d3d3 !important;
                }
              }
            }
          }
          .tela-description {
            color: #d3d3d3 !important;
          }
        }
      }
    }
    &:hover {
      cursor: no-drop !important;
    }
  }
`;

export const CardLink = styled.div`
  display: inline !important;
  outline: none !important;
  text-decoration: none !important;
  cursor: pointer !important;

  span {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: normal !important;

    cursor: inherit !important;
  }

  &.blocked {
    cursor: no-drop !important;
  }

  @media (min-width: 940px) {
    span {
      font-size: 18px !important;
    }
  }
`;
