/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';

import { TrilhaContainer, TrilhaHeader, TrilhaTelasContainer } from './styles';
import Separator from '~/components/Separator';
import { NavigationContext } from '~/context/navigation';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

interface TrilhaProps {
  trilhas: any[];
  trilhasFilter: any[];
  handleOpenTela?: (item: any) => void;
  handleAddAccessed?: (cod_tela: any) => Promise<void>;
  handleFiltroPorTrilha: (cod_trilha: any) => void;
  blocked: boolean;
}

const Trilha: React.FC<TrilhaProps> = (props) => {
  const {
    trilhas,
    trilhasFilter,
    blocked = false,
    handleOpenTela = () => null,
    handleAddAccessed = () => null,
    handleFiltroPorTrilha,
  } = props;
  const { screens, setScreenActive } = useContext(NavigationContext);
  const [trilhaActive, setTrilhaActive] = useState(0);
  const queryClient = useQueryClient();

  return (
    <>
      <TrilhaHeader>
        {trilhasFilter.length > 0 ? (
          <div>
            <button
              type="button"
              className={`li-buttonTrilha ${
                trilhaActive === 0 ? 'selected' : ''
              }`}
              onClick={() => {
                setTrilhaActive(0);
                handleFiltroPorTrilha(0);
              }}
            >
              Todas as trilhas
            </button>
          </div>
        ) : (
          <div className="title-modulo">
            <h2>
              Nenhuma trilha disponível. Consulte seu administrador do sistema.
            </h2>
          </div>
        )}
        {trilhasFilter.map((trilha: any) => {
          return (
            <div key={trilha.cod_trilha}>
              <button
                type="button"
                className={`li-buttonTrilha ${
                  trilhaActive === trilha.cod_trilha ? 'selected' : ''
                }`}
                onClick={() => {
                  setTrilhaActive(trilha.cod_trilha);
                  handleFiltroPorTrilha(trilha.cod_trilha);
                }}
              >
                <div className="icon">{trilha.Icon && <trilha.Icon />}</div>
                {trilha.des_trilha}
              </button>
            </div>
          );
        })}
      </TrilhaHeader>
      <TrilhaContainer>
        {trilhas.length > 0 ? (
          trilhas.map((trilha: any) => {
            return (
              <>
                <Separator
                  lineColor="#9900ff"
                  color="#fff"
                  background="#46057e"
                  childrenWidth="160px"
                  labelText={trilha.des_trilha}
                />
                <TrilhaTelasContainer>
                  {trilha.telas?.map((tela: any) => {
                    return (
                      <Link
                        to={blocked ? '/app' : tela.url}
                        style={{
                          color: 'inherit',
                          textDecoration: 'inherit',
                        }}
                        key={tela.cod_tela}
                        onClick={(e) => {
                          /**
                           * Ao tentar adicionar uma tela já aberta, fazemos um
                           * find para verificar a existência da tela, e se
                           * ela já estiver aberta setamos a aba como ativa.
                           * Se for uma tela nova impede a navegação para a rota da tela selecionada
                           * quando já estamos com o total de telas abertas.
                           */

                          if (screens.length >= 6) {
                            const filteredScreens = screens.find(
                              (item) => item.cod_tela === tela.cod_tela,
                            );
                            if (filteredScreens) {
                              queryClient.setQueryData(`cod_tela_active`, {
                                cod_tela: filteredScreens.cod_tela,
                              });
                              setScreenActive(filteredScreens.cod_tela);
                              return;
                            }
                            e.preventDefault();
                          }
                        }}
                      >
                        <button
                          type="button"
                          className="li-buttonTrilhaTela selected"
                          onClick={(e) => {
                            if (blocked) return;
                            setTrilhaActive(0);
                            handleFiltroPorTrilha(0);
                            handleAddAccessed(tela.cod_tela);
                            handleOpenTela(tela);
                          }}
                        >
                          <div className="icon">
                            {tela.Icon && <tela.Icon />}
                          </div>{' '}
                          {tela.des_tela}
                        </button>
                      </Link>
                    );
                  })}
                </TrilhaTelasContainer>
              </>
            );
          })
        ) : trilhasFilter.length > 0 ? (
          <div className="title-modulo">
            <h2>Nenhuma tela encontrada para o filtro digitado.</h2>
          </div>
        ) : (
          ''
        )}
      </TrilhaContainer>
    </>
  );
};

export default Trilha;
